import firebase from "firebase/compat/app"
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDJZUfPc16Ge40u5GERkxEm3FPGUxC3h5g",
    authDomain: "storage-qrcode.firebaseapp.com",
    projectId: "storage-qrcode",
    storageBucket: "storage-qrcode.appspot.com",
    messagingSenderId: "621342429298",
    appId: "1:621342429298:web:4620d7a88a36e0f0fe72eb",
    measurementId: "G-WLH193P9F5"
};


// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const storage = firebase.storage(); // Acesso ao Firebase Storage
export { storage, app };

export default app