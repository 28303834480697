import React,{useState} from 'react'
import closeEyeIcon from './images/svg/closeEyeIcon.svg'
import openEyeIcon from './images/svg/openEyeIcon.svg'
import InputMask from 'react-input-mask';
import './styles/style.css'
const Input = ({name,type,placeholder,onChange,width,error,height,marginLeft,color,money,value,onClick,maxLength,topEye,mask,disabled,marginBottom,marginTop,fontSize}) => {

    
    const [stateEye,setStateEye] = useState(false)
    
    const containerInput = {
        display:"flex",
        flexDirection:"column",
        marginLeft:marginLeft,
        width:width,
    }
    const styleInput = {
        width:width != undefined ? width :"418px",
        color:color != undefined ? color : "",
        height:height != undefined ? height : "50px",
        borderRadius:"10px",
        border:"1px solid #D7D7D7",
        marginBottom:marginBottom != undefined ? marginBottom :"1.9rem",
        outline:"none",
        backgroundColor: disabled && "#f4f4f4"
    }
    const styleLabel = {
        fontSize:fontSize,
        marginBottom:"14.43px",
    }
    const containerInputMail = {
        width:width != undefined ? width :"418px",

        display:"flex",
        flexDirection:"column",
        position:"relative",

    }
    const containerInputPassowrd = {
        display:"flex",
        marginTop:marginTop,
        flexDirection:"column",
        position:"relative",
        width:width != undefined ? width :"418px",

    }

    return(

        <div className='container_input' >
        
        {type == "password" 
        
        ?
        <div className='container_input'>
        <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>
        <input  onChange={onChange} placeholder={placeholder} type={stateEye == true ? "text" :type} className="input_style_landing" />

        {stateEye == false 
        
        ?
        <img src={closeEyeIcon} onClick={() => setStateEye(!stateEye)} className='close_icon_input' />
        :
        <img src={openEyeIcon} onClick={() => setStateEye(!stateEye)}  className='open_icon_input' />

        }

        {error != undefined 
        
        ?
        <p className="textErrorInput">{error}</p>
        :
        <></>
        }
        </div>
        :

        <div className='container_input'>
        <label className="textPrimaryLabelInput" style={styleLabel}>{name}</label>
            
        <InputMask onClick={onClick} disabled={disabled} maskChar="" mask={mask} maxLength={maxLength} value={value} onChange={onChange} placeholder={placeholder} type={type} className="input_style_landing" />
        {error != undefined 
        
        ?
        <p className="textErrorInput">{error}</p>
        :
        <></>
        }
        </div>
        }


        </div>


    )

}

export default Input