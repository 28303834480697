import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import companyIcon from '../../images/png/backgroundCompanyIcon2.png'
import exitIcon from '../../images/svg/exitIcon.svg'
import manualIcon from '../../images/svg/manualIcon.svg'
import manualCheckIcon from '../../images/svg/manualCheckIcon.svg'
import kitIcon from '../../images/svg/kitIcon.svg'
import kitCheckIcon from '../../images/svg/kitIconCheck.svg'
import dashboardIcon from '../../images/svg/dashBoardIcon.svg'
import dashboardCheckIcon from '../../images/svg/dashboardCheck.svg'

const Sidebar = () => {
    const [option, setOption] = useState(1)

    const navigate = useNavigate()

    const exit = () => {
        localStorage.clear()
        window.location.href = "/"
    }



    return (

        <div className="sidebar">
            <img className='company_sidebar' src={companyIcon} />

            {localStorage.getItem("@PERMISSION") == "5"

                ?
                <></>
                :
                window.location.pathname == "/dashboard/" || window.location.pathname == "/dashboard"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard") }}>
                        <img src={dashboardCheckIcon} />
                        <p>Dashboard</p>
                    </div>
                    :

                    <div className='not_checked' onClick={() => { navigate("/dashboard/") }}>
                        <img src={dashboardIcon} />
                        <p>Dashboard</p>
                    </div>
            }



            {localStorage.getItem("@PERMISSION") == "5"

                ?
                <></>
                :
                window.location.pathname == "/dashboard/files"

                    ?
                    <div className='checked' onClick={() => { navigate("/dashboard/files") }}>
                        <img src={manualCheckIcon} />
                        <p>Manuais</p>
                    </div>
                    :

                    <div className='not_checked' onClick={() => { navigate("/dashboard/files") }}>
                        <img src={manualIcon} />
                        <p>Manuais</p>
                    </div>
            }



            <div className='not_checked' onClick={() => exit()}>
                <img className='exit_icon' src={exitIcon} />
                <></>
            </div>
        </div>

    )
}

export default Sidebar