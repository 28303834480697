import logo from './logo.svg';
import './App.css';
import QRCode from "react-qr-code";

function App() {
  return (
    <div className="App">

      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "220px", width: "220px" }}
        value={"https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/BRACONE%20BRC-FD-08%20-%20FIESTA%20HATCH%202002%20EM%20DIANTE.pdf?alt=media&token=d4cf9cb1-a1db-4201-bb03-69572e0fed4b"}
        viewBox={`0 0 256 256`}
      />

    </div>
  );
}

export default App;
