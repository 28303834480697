import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment';
import 'moment/locale/pt-br'; // Importa a localização em português para o moment
import { FileContext } from '../../context/files';
const TableList = ({ table, financial, update }) => {

    const { get, files, getProduct,getQrCode,getMultiples } = useContext(FileContext)

    const [selectedIds, setSelectedIds] = useState([]);
    useEffect(() => {
        get()
    }, [update])

    useEffect(() => {
        get()
    },[])


        
    return (

        <div className='container_startpn_table'>
            <table>

                <thead>
                    <tr>
                        <td>Data</td>
                        <td>Categoria</td>
                        <td className='table_revision'>Revisão</td>
                        <td>Opções</td>

                    </tr>
                </thead>


                <tbody>
                    {files?.map(item => {
                        const formatedDate = moment(item.createdAt).format('DD/MM/YYYY');

                        return (

                            <tr>
                                <td data-toggle="modal" onClick={() => getQrCode(item.file_id)}> {formatedDate}</td>
                                <td data-toggle="modal" onClick={() => getQrCode(item.file_id)}>
                                  <img src={item.category_image} style={{width:"41px",height:"41px",objectFit:"cover"}}/>
                                </td>
                                <td className='table_revision'>{item.revision}</td>
               


                                <td data-toggle="modal" data-target="#options"  onClick={() => getQrCode(item.file_id)} >

                                    <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="1.93767" cy="1.93767" rx="1.93767" ry="1.93767" fill="black" />
                                        <ellipse cx="1.93767" cy="8.81316" rx="1.93767" ry="1.93767" fill="black" />
                                        <ellipse cx="1.93767" cy="15.6882" rx="1.93767" ry="1.93767" fill="black" />
                                    </svg>

                                </td>

                            </tr>

                        )
                    })}



                </tbody>

            </table>
        </div>
    )
}

export default TableList