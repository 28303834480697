import React, { createContext, useState } from 'react'
import api from '../services/api'

export const ProductContext = createContext({})

function ProductProvider({ children }) {

    const [dataProduct, setDataProduct] = useState([])
    const [produts, setProducts] = useState()

    const getProduct = (name, image, id) => {
        setDataProduct({
            name: name,
            image: image,
            id: id
        })
    }
    const get = async () => {
        const response = await api.get("panel/all/products")
        if (response.status == 200) {
            setProducts(response.data)
        }
    }
    const storage = async () => {
    }
    const edit = async () => {
    }

    return (
        <ProductContext.Provider value={{
            getProduct,
            dataProduct,
            get,
            produts,
            setProducts,
            storage,
            edit,
        }}>
            {children}
        </ProductContext.Provider>
    )

}

export default ProductProvider