import React from 'react'

const Select = ({ name, data, onChange }) => {
    return (

        <div className='container_select'>
            <label className="textPrimaryLabelInput" >{name}</label>
            <select onChange={onChange}>
                <option>Selecione</option>
                {data?.map(item => {
                    return <option value={item.product_id}>{item.name}</option>

                })}
            </select>

        </div>

    )
}

export default Select